<!--
   User: Liu Yin
   Date: 2020/3/12
   Description:人才测评
 -->
<template>
  <article>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><span @click="close">资料库</span></el-breadcrumb-item>
        <el-breadcrumb-item>资料列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="position: relative; margin: 0 auto; max-width: 1260px">
      <el-input v-model="searchForm.keyWords" placeholder="搜索资料名称" class="inputwidth" @change="requestData">
        <i slot="suffix" class="el-icon-search"></i>
      </el-input>
    </div>
    <el-row :gutter="10" style="display: flex; align-items: center; justify-content: center">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div>
          <el-card shadow="always" class="label_box">
            <div>
              <div class="directe">
                <ul v-for="(item, index) in library" :key="index">
                  <li :class="item.dictValue === dictValue ? 'li_active' : ''" @click="queryType(item)">
                    {{ item.dictValue }}
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>

    <div style="max-width: 1280px;margin:0 auto">
      <el-row :gutter="20">
        <el-col v-for="(item,index) in records" :key="index" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="bgpaddmar" @click="requestUserInfo(item)">
            <ul>
              <li>
                <article class="el-news-right-item">
                  <div>
                    <div class="overhidden">
                      <svg-icon :icon-class="converter(item.fileType)"></svg-icon>
                      <span class="news-item-title" :title="item.fileName">
                        {{ item.fileName }}
                      </span>
                    </div>
                    <p class="news-item-content">
                      {{ item.createTime | moment('YYYY-MM-DD') }}
                    </p>
                    <div class="news-item-bottom">
                      <svg-icon icon-class="xiazai"></svg-icon>
                      <span class="bottom-item">{{ item.downloadCount }}</span>
                    </div>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </el-col>
      </el-row>
    </div>
    <div style="text-align: center;">
      <el-pagination class="pageAction" :current-page.sync="currentPage" :page-size="size" layout="prev, pager, next, jumper" :total="total" @current-change="handleCurrentChange" />
    </div>
  </article>
</template>

<script>
import listMixin from '../../../../../utils/mixins/listMixin'
import { $downloadDocument } from '../../../../../utils/request'
export default {
  components: {
  },
  mixins: [listMixin],
  props: {
    // this.row.category
    row: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      firstLevel: [],
      category: '',
      searchForm: {},
      records: [],
      library: [],
      dictValue: '全部'
    }
  },
  created() {
    this.dictValue = this.row.categoryName
    this.searchForm.fileCategory = this.row.categoryCode
    this.requestData()
    this.getDictObj()
  },
  methods: {
    // 获取字典数据
    getDictObj() {
      this.$get(`${this.urls.dicValues}`, {
        types: 'library'
      })
        .then((result) => {
          if (result.code === 'SUCCESS') {
            const data = result.data
            this.library = data.library
            this.library.unshift({ dictValue: '全部', dictCode: '' })
          } else {
            this.$message.error(result.msg)
          }
        })
        .catch(() => {
          this.toggleLoading(false)
        })
    },
    requestUserInfo(val) {
      this.$get(this.urls.limitation, null, [val.id]).then(result => {
        if (result.code === 'SUCCESS') {
          $downloadDocument(this.urls.libraryDownloadFile + '/' + val.id, val.fileName)
          this.$get(this.urls.incrementDownloadCount, null, [val.id]).then((result) => {
            this.requestData()
          })
        } else {
          this.$message.error('不同文件每天只可下载5份')
        }
      }).catch(err => {
        console.log(err)
      })
    },
    queryType(row) {
      this.searchForm.fileCategory = row.dictCode
      this.dictValue = row.dictValue
      this.requestData()
    },
    requestData() {
      this.$get(this.urls.librarypage, this.searchForm, [
        this.currentPage,
        12
      ])
        .then((result) => {
          if (result.code === 'SUCCESS') {
            const data = result.data
            this.records = data.records
            this.total = data.total
          } else {
            this.$message.error(result.msg)
          }
        })
        .catch((err) => {
          console.log('err==', err)
        })
    },
    // 判断图标转换
    converter(suffix) {
      const lowerCaseSuffix = suffix.toLowerCase()
      if (lowerCaseSuffix === 'jpg' || lowerCaseSuffix === 'jpeg') {
        return 'jpg'
      } else if (lowerCaseSuffix === 'pdf') {
        return 'pdf'
      } else if (lowerCaseSuffix === 'txt') {
        return 'txt'
      } else if (lowerCaseSuffix === 'zip') {
        return 'zip'
      } else if (lowerCaseSuffix === 'md') {
        return 'md'
      } else if (lowerCaseSuffix === 'doc' || lowerCaseSuffix === 'docx') {
        return 'doc'
      } else if (lowerCaseSuffix === 'ppt' || lowerCaseSuffix === 'pptx') {
        return 'ppt'
      } else if (lowerCaseSuffix === 'mp3') {
        return 'mp3'
      } else if (lowerCaseSuffix === 'png') {
        return 'png'
      } else if (lowerCaseSuffix === 'mp4' || lowerCaseSuffix === 'm4a') {
        return 'mp4'
      } else if (lowerCaseSuffix === 'xls' || lowerCaseSuffix === 'xlsx') {
        return 'xls'
      } else {
        return 'file'
      }
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.label_box {
  max-width: 1260px;
  border-radius: 8px;
  // margin: 24px 0 40px 0;
  margin: 24px auto;
  width: 100%;
}
ul {
  display: inline-block;
  li {
    box-sizing: border-box;
    // margin: 0 8px 8px 0;
    padding: 6px 8px;
    color: #4e5969;
    font-size: 16px;
    line-height: 20px;
    border-radius: 4px;
    cursor: pointer;
  }
}
.directe {
  margin-bottom: 10px;
}
.li_active {
  color: #2c6efb;
  background-color: #e8f3ff;
}
.el-row {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.bgpaddmar {
  position: relative;
  background: #fff;
  margin: 10px 0;
  padding: 10px;
  min-height: 120px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
  cursor: pointer;
}
.bgpaddmar:hover {
  .news-item-title {
    color: #29a1f7;
  }
  box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
}
.overhidden {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.news-item-content {
  font-size: 12px;
  color: #bbb;
  margin-top: 15px;
}
.news-item-bottom {
  position: absolute;
  bottom: 15px;
  right: 15px;
  color: #999;
}
.breadcrumb {
  width: 1280px;
  padding: 20px 10px;
  margin: 0 auto;
  cursor: pointer;
}
.inputwidth {
  position: relative;
  // width: 43%;
  // position: absolute;
  top: 7px;
  right: 0px;
}
::v-deep .el-input__suffix {
  position: absolute;
  top: 12px !important;
}
::v-deep .el-pagination {
  button {
    background: initial;
    .el-icon {
      font-size: 17px;
    }
  }
  .number {
    background: initial;
    font-size: 16px;
  }
}
</style>
