<template>
  <article>
    <div v-if="showFlag===1">
      <swiperbanner
        class="banner"
        :dict-code="Banner.BANNER_DATABASE"
      ></swiperbanner>
      <div v-for="(item, index) in databaseList"
           :key="index" style="max-width: 1280px; margin: 0 auto;position: relative;">
        <div class="seemore" @click="viewmore(item)">查看更多 ></div>
        <div class="title">
          <span>{{ item.categoryName }}</span>

        </div>
        <el-row :gutter="20">
          <el-col
            v-for="(val,num) in item.list" :key="num"
            :xs="4"
            :sm="4"
            :md="4"
            :lg="4"
            :xl="4"
          >
            <div class="informationDiv" @click="requestUserInfo(val)">
              <div class="imgDiv">
                <svg-icon :icon-class="converter(val.fileType)"></svg-icon>
              <!-- <img src="../../../../assets/images/labour/monitor.png" alt=""> -->
              </div>
              <div>
                <div class="name">{{ val.fileName }}</div>
                <div class="number">{{ val.downloadCount }}   下载</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <more v-if="showFlag===2" :row="row" @close="close"></more>
  </article>
</template>

<script>
import { Banner } from '../../../../utils/dict'
import Swiperbanner from '../../../../components/swiperbanner.vue'
import More from './components/more.vue'
import { $downloadDocument } from '../../../../utils/request'

export default {
  components: {
    Swiperbanner,
    More
  },

  data() {
    return {
      Banner,
      databaseList: [],
      showFlag: 1

    }
  },
  mounted() {
    document.body.scrollTop = 0
    this.requestData()
  },
  methods: {
    requestUserInfo(val) {
      this.$get(this.urls.limitation, null, [val.id]).then(result => {
        if (result.code === 'SUCCESS') {
          $downloadDocument(this.urls.libraryDownloadFile + '/' + val.id, val.fileName)
          this.$get(this.urls.incrementDownloadCount, null, [val.id]).then((result) => {
            this.requestData()
          })
        } else {
          this.$message.error('不同文件每天只可下载5份')
        }
      })
    },
    close() {
      this.showFlag = 1
    },
    viewmore(row) {
      this.showFlag = 2
      this.row = row
    },
    requestData() {
      this.toggleLoading(true)
      this.$get(this.urls.libraryHome, null, null)
        .then((result) => {
          this.toggleLoading(false)
          if (result.code === 'SUCCESS') {
            const data = result.data
            this.databaseList = data
          } else {
            this.$message.error(result.msg)
          }
        })
        .catch(() => {
          this.toggleLoading(false)
        })
    },
    // 判断图标转换
    converter(suffix) {
      const lowerCaseSuffix = suffix.toLowerCase()
      if (lowerCaseSuffix === 'jpg' || lowerCaseSuffix === 'jpeg') {
        return 'jpg'
      } else if (lowerCaseSuffix === 'pdf') {
        return 'pdf'
      } else if (lowerCaseSuffix === 'txt') {
        return 'txt'
      } else if (lowerCaseSuffix === 'zip') {
        return 'zip'
      } else if (lowerCaseSuffix === 'md') {
        return 'md'
      } else if (lowerCaseSuffix === 'doc' || lowerCaseSuffix === 'docx') {
        return 'doc'
      } else if (lowerCaseSuffix === 'ppt' || lowerCaseSuffix === 'pptx') {
        return 'ppt'
      } else if (lowerCaseSuffix === 'mp3') {
        return 'mp3'
      } else if (lowerCaseSuffix === 'png') {
        return 'png'
      } else if (lowerCaseSuffix === 'mp4' || lowerCaseSuffix === 'm4a') {
        return 'mp4'
      } else if (lowerCaseSuffix === 'xls' || lowerCaseSuffix === 'xlsx') {
        return 'xls'
      } else {
        return 'file'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 430px;
}
.title {

  color: #252b3a;
  text-align: center;
  padding: 30px;
  line-height: 46px;
      font-size: 20px;
    font-weight: 700;
    color: #3D3D3D;
  font-family: Hiragino Sans GB, Helvetica Neue, Helvetica, STHeiTi,
    Microsoft Yahei, sans-serif;
}
.seemore{
  position: absolute;
  right: 10px;
  top: 48px;
      font-size: 14px;
    font-weight: 400;
    color: #939FB7;
    cursor: pointer;
}
.seemore:hover{
      color: #29A1F7;
}
.title span {
  position: relative;
}

.title span:after {
  position: absolute;
  content: " ";
  left: -16px;
  top: 50%;
  margin-top: -10px;
  width: 4px;
  height: 20px;
  background: linear-gradient(135deg, #2791ff 0, #03a7ff 98%);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.imgDiv{
  width: 50px;
  margin-right: 10px;
  img{
        width: 100%;
    height:66px;
    object-fit: contain;
  }
}
.name{
height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #333;
    max-width: 130px;
}

.number{
// margin-top: 10px;
    font-size: 12px;
    font-weight: 400;
    color: #999;
    line-height: 16px;
}
.informationDiv{
    margin: 10px 0;
  display: flex;
  background: #fff;
    box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
    padding: 6px;
    cursor: pointer;
}
.informationDiv:hover{
  box-shadow: 0 1px 12px rgb(0 0 0 / 10%);
.name{
    color: #29A1F7;

}
}
.svg-icon{
  font-size: 50px;
}
.el-row {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
</style>
